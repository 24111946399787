@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.preview-container {
  display: flex;
  flex-direction: column;
}

.preview-container-header {
  font-family: "AlianzaSlab700";
  font-size: 24px;
  margin-bottom: 34px;

  color: #363431;
}

.preview-container-title {
  font-family: "AlianzaSlab700";
  font-size: 16px;
  margin-bottom: 34px;

  color: #363431;
}

.subscriptions {
  background: #242321;
  width: 375px !important;
  margin-bottom: 40px;
}

.bulletpoint-container {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.bulletpoint {
  display: flex;
  flex-direction: row;

  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;

  color: #ccc9bc;

  margin-left: 40px;
  margin-bottom: 16px;
}

.bulletpoint > span {
  margin-left: 8px;
}

.cta-container {
  background-color: #242321;
  padding: 16px;
}

.cta-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f45e45;
  color: #e9e6d6;

  font-family: "Roboto";
}

.cta-btn-title {
  font-weight: 700;
  font-size: 16px;
}

.cta-btn-sub-title {
  font-weight: 400;
  font-size: 12px;
}

.cancel-container {
  display: flex;
  flex-direction: column;
  height: 248px;
}

.cancel-container > span {
  color: #ccc9bc;
  margin-left: 48px;
  margin-right: 48px;
}

span.cancel-title {
  font-size: 14px;
  font-weight: 700px;
  font-family: "Roboto";

  margin-top: 40px;
}

span.cancel-body {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";

  margin-top: 8px;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 240px;

  font-family: "AlianzaSlab700";
  font-size: 28px;
  color: #e9e6d6;
  padding: 20px;
}

.header > img {
  position: absolute;
  top: 0;
  left: 0;
}

.header-other {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 300px;

  font-family: "AlianzaSlab700";
  font-size: 28px;
  color: #e9e6d6;
  padding: 20px;
}

.header-background {
  position: absolute;
  top: 20px;
  left: 0;
}

.header-other > .status-bar {
  position: absolute;
  top: 0;
  left: 0;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #e9e6d6;
}

.header-content > span.header-title {
  margin-left: 30px;
  margin-right: 30px;
  font-family: "AlianzaSlab700";
  font-size: 28px;
  line-height: 34px;
}

.header-content > span.header-subtitle {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 14px;
}

.legal-section {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;

  background-color: #363431;
}

span.legal-note,
span.legal-links,
span.restore-link {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  color: #978e74;
  text-align: center;
}

span.legal-note {
  margin-top: 40px;
  margin-left: 48px;
  margin-right: 48px;
}

span.restore-link {
  font-weight: 700;
  color: #f45e45;

  margin-top: 24px;
}

span.legal-links {
  color: #f45e45;
  margin-top: 16px;
  margin-bottom: 40px;
}

.preview-container {
  display: flex;
  flex-direction: column;
}

.preview-container-header {
  font-family: "AlianzaSlab700";
  font-size: 24px;
  margin-bottom: 34px;

  color: #363431;
}

.preview-container-title {
  font-family: "AlianzaSlab700";
  font-size: 16px;
  margin-bottom: 34px;

  color: #363431;
}

.pricebox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.pricebox {
  display: flex;
  flex-direction: row;
  margin: 30px 24px;

  border: 2px solid #363431;
  border-radius: 8px;
}

.pricing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: "Roboto";
  color: #c1baa1;

  margin-left: 16px;
  margin-right: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.pricing-yearly {
  font-size: 14px;
  font-weight: 400;
}

.pricing-monthly {
  font-family: "AlianzaSlab700";
  font-size: 16px;
  font-weight: 700;
}

.pricing-equals {
  font-size: 14px;
  font-weight: 400;
}

.best-price {
  position: absolute;
  right: 0px;
  top: calc(50% - 10px);

  font-family: "Roboto";
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;

  color: #363431;
}

.best-price > span {
  position: absolute;
  right: 0px;
  width: 100px;
  text-align: right;

  padding-left: 8px;
  padding-right: 8px;
  z-index: 100;
}

.best-price > img {
  position: absolute;
  right: 0px;
}

span.other-subscriptions {
  margin-top: 24px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
  color: #f45e45;

  text-transform: uppercase;
  margin-bottom: 32px;
}

.pricebox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.pricebox {
  display: flex;
  position: relative;
  flex-direction: row;
  width: calc(100% - 48px);
  margin: 16px 24px;

  border: 2px solid #363431;
  border-radius: 8px;
}

.pricebox#best-value {
  margin-bottom: 0px;
  border-color: #f45e45;
}

.pricing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: "Roboto";
  color: #c1baa1;

  margin-left: 16px;
  margin-right: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.pricing-yearly {
  font-size: 14px;
  font-weight: 400;
}

.pricing-monthly {
  font-family: "AlianzaSlab700";
  font-size: 16px;
  font-weight: 700;
}

.pricing-equals {
  font-size: 14px;
  font-weight: 400;
}

.best-price {
  position: absolute;
  right: 0px;
  top: calc(50% - 10px);

  font-family: "Roboto";
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;

  color: #363431;
}

.best-price > span {
  position: absolute;
  right: 0px;
  width: 100px;
  text-align: right;

  padding-left: 8px;
  padding-right: 8px;
  z-index: 100;
}

.best-price > img {
  position: absolute;
  right: 0px;
}

span.other-subscriptions {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
  color: #f45e45;

  text-transform: uppercase;
  margin-bottom: 32px;
}

.timeline {
  padding-left: 25px;
  padding-right: 22px;
  margin-top: 17px;

  position: relative;
  display: flex;
  align-items: row;

  font-family: "Roboto";
  font-size: 28px;
  color: #e9e6d6;
}

.timeline > img {
  top: 0;
  left: 0;
}

.timeline > .content {
  display: flex;
  flex-direction: column;
}

.timeline > .content > div {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

span.timeline-title {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
  color: #e9e6d6 !important;
}

span.timeline-body {
  font-size: 14px;
  font-weight: 400;
  color: #e9e6d6;
}
